@import "../../variables.modules";

h1 {
  font-size: 2rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 3rem;
    text-align: left;
  }
}

h2 {
  font-size: 1.25rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 2rem;
    text-align: left;
  }
}


.hand {
  animation-name: wave;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

a {
  color: $dark;
  transition: color 200ms ease;
}

a:hover {
  color: $lightgray;
  transition: color 200ms ease;
}

@keyframes wave {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}

/* Home.module.scss */

/* Home.module.scss */

.resumebutton {
  background: transparent;
  border: 1px solid lightblue;
  color: inherit;
  font-size: large;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
  border-radius: 0.3rem;
  color: #fff; /* Text color */
  font-weight: bold;
  transition: background 1.3s ease; /* Slow transition */

  &:hover {
    background: rgb(0, 0, 0); /* Background changes to black on hover */
  }

  span {
    background: inherit;
    color: inherit;
  }
}
